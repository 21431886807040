<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <AForm
            layout="inline"
            ref="filterFormRef"
            :model="state.params"
            :rules="state.param_rules">
            <div class="row">
                <div class="col-md-12">
                    <AFormItem
                        name="start_date"
                        required>
                        <AMonthPicker
                            v-model:value="state.params.start_date"
                            :disabled-date="disabledStartDate"
                            placeholder="Bulan Awal"
                            style="width:300px;"
                            :allow-clear="false"
                            format="MMMM YYYY"/>
                    </AFormItem>
                    <AFormItem
                        name="end_date"
                        required>
                        <AMonthPicker
                            v-model:value="state.params.end_date"
                            :disabled-date="disabledEndDate"
                            placeholder="Bulan Akhir"
                            style="width:300px;"
                            :allow-clear="false"
                            format="MMMM YYYY"/>
                    </AFormItem>
                    <AFormItem
                        name="distributor"
                        required>
                        <FilterDistributorCurah
                            :mode="null"
                            :allow-clear="false"
                            v-model:value="state.params.distributor"/>
                    </AFormItem>
                    <AButton
                        class="mr-2"
                        type="primary"
                        title="cari"
                        @click="fetchDataList"
                        :loading="state.isFetching">
                        <span
                            v-if="!state.isFetching"
                            class="fa fa-search"
                            aria-hidden="true"/>
                        <span v-else>
                            Memuat Data ...
                        </span>
                    </AButton>
                </div>
            </div>
        </AForm>

        <div class="card mt-4">
            <div class="card-body">
                <EChartCustom
                    :loading="state.shipping.loading"
                    :labelx="state.shipping.labelx"
                    :labely="state.shipping.labely"
                    :tooltip="state.shipping.tooltip"
                    :series="state.shipping.series"
                    title="Grafik Konfirmasi Pengiriman"/>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12">
                <strong>Summary GPM Perkelompok Pelanggan</strong>
            </div>
            <div class="col-lg-6 col-md-12 text-right">
                <a-button
                    title="download excel"
                    type="primary"
                    @click="btnDownloadExcelCustomer"
                    :loading="state.loading.customerDownloading">
                    <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.loading.customerDownloading"></i>
                    <span v-else>Downloading ...</span>
                </a-button>
            </div>
        </div>

        <!-- list table customer -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns.customer"
                :data-source="state.data.customer"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.customer.total,
                    pageSize: state.meta.customer.per_page,
                    current: state.meta.customer.page,
                }"
                @change="handleTableChangeCustomer"
                :loading="state.loading.customer">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.customer.page - 1) * state.meta.customer.per_page + 1 + index }}
                    </span>
                </template>
            </MdTable>
        </div>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12">
                <strong>Summary GPM Perdistributor</strong>
            </div>
            <div class="col-lg-6 col-md-12 text-right">
                <a-button
                    title="download excel"
                    type="primary"
                    @click="btnDownloadExcelDistributor"
                    :loading="state.loading.distributorDownloading">
                    <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.loading.distributorDownloading"></i>
                    <span v-else>Downloading ...</span>
                </a-button>
            </div>
        </div>

        <!-- list table customer -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns.distributor"
                :data-source="state.data.distributor"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.distributor.total,
                    pageSize: state.meta.distributor.per_page,
                    current: state.meta.distributor.page,
                }"
                @change="handleTableChangeDistributor"
                :loading="state.loading.distributor">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.distributor.page - 1) * state.meta.distributor.per_page + 1 + index }}
                    </span>
                </template>
            </MdTable>
        </div>

        <div class="card mt-4">
            <div class="card-body">
                <EChartCustom
                    :loading="state.cash_in_out.loading"
                    :labelx="state.cash_in_out.labelx"
                    :labely="state.cash_in_out.labely"
                    :series="state.cash_in_out.series"
                    title="Grafik Cash In & Cash Out"/>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12">
                <strong>Summary Tagihan</strong>
            </div>
            <div class="col-lg-6 col-md-12 text-right">
                <a-button
                    title="download excel"
                    type="primary"
                    @click="btnDownloadExcelBill"
                    :loading="state.loading.billDownloading">
                    <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.loading.billDownloading"></i>
                    <span v-else>Downloading ...</span>
                </a-button>
            </div>
        </div>

        <!-- list table bills -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns.bill"
                :data-source="state.data.bill"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.bill.total,
                    pageSize: state.meta.bill.per_page,
                    current: state.meta.bill.page,
                }"
                @change="handleTableChangeBill"
                :loading="state.loading.bill">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.bill.page - 1) * state.meta.bill.per_page + 1 + index }}
                    </span>
                </template>
            </MdTable>
        </div>

        <div class="card mt-4">
            <div class="card-body">
                <EChartCustom
                    :loading="state.selling.loading"
                    :labelx="state.selling.labelx"
                    :labely="state.selling.labely"
                    :series="state.selling.series"
                    title="Grafik kinerja Penjualan"/>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch } from 'vue'
import apiClient from '@/services/axios'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import EChartCustom from '@/components/charts/EChartCustom'
import { includes } from 'lodash'
import moment from 'moment'
import {
    ROLE_KAM,
    hasRoles,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        FilterDistributorCurah,
        EChartCustom,
    },
    setup() {
        const errorMessage = ref()
        const filterFormRef = ref('')
        const state = reactive({
            columns: {
                customer: [
                    {
                        title: 'NO',
                        dataIndex: 'no',
                    },
                    {
                        title: 'Kelompok Pelanggan',
                        dataIndex: 'group_name',
                    },
                    {
                        title: 'Harga Tebus',
                        dataIndex: 'harga_tebus',
                        customRender: ({ text }) => text.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    },
                    {
                        title: 'CBP',
                        dataIndex: 'cbp',
                        customRender: ({ text }) => text.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    },
                    {
                        title: '%GPM',
                        dataIndex: 'gpm',
                    },
                ],
                distributor: [
                    {
                        title: 'NO',
                        dataIndex: 'no',
                    },
                    {
                        title: 'Distributor',
                        dataIndex: 'distributor',
                    },
                    {
                        title: 'Total Cost',
                        dataIndex: 'total_harga',
                        customRender: ({ text }) => text.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    },
                    {
                        title: 'Total Revenue',
                        dataIndex: 'total_revenue',
                        customRender: ({ text }) => text.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    },
                    {
                        title: '%GPM',
                        dataIndex: 'gpm',
                    },
                ],
                bill: [
                    {
                        title: 'No',
                        dataIndex: 'no',
                    },
                    {
                        title: 'Distributor',
                        dataIndex: 'distributor',
                    },
                    {
                        title: 'Total Tagihan',
                        dataIndex: 'total_tagihan',
                        customRender: ({ text }) => text.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    },
                    {
                        title: 'Terbayar',
                        dataIndex: 'terbayar',
                        customRender: ({ text }) => text.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    },
                    {
                        title: 'Jatuh Tempo (Belum Bayar)',
                        dataIndex: 'sudah_jatuh_tempo',
                        customRender: ({ text }) => text.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    },
                    {
                        title: 'Belum Jatuh Tempo (Belum Bayar)',
                        dataIndex: 'belum_jatuh_tempo',
                        customRender: ({ text }) => text.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                    },
                    {
                        title: 'Persentase Tagihan Terbayar',
                        dataIndex: 'persentase',
                    },
                ],
            },
            data: {
                customer: [],
                distributor: [],
                bill: [],
            },
            meta: {
                customer: {
                    per_page: 10,
                    page: 1,
                    total: 0,
                },
                distributor: {
                    per_page: 10,
                    page: 1,
                    total: 0,
                },
                bill: {
                    per_page: 10,
                    page: 1,
                    total: 0,
                },
            },
            loading: {
                customer: false,
                distributor: false,
                customerDownloading: false,
                distributorDownloading: false,
            },
            params: extractQueryParams({
                q: '',
                start_date: moment(new Date(), 'YYYY-MM').startOf('year'),
                end_date: moment(new Date(), 'YYYY-MM').endOf('year'),
                distributor: [],
                page: 1,
                "per-page": 10,
            }),
            param_rules: {
                start_date: [
                    {
                        required: true,
                        message: 'Bulan awal tidak boleh kosong',
                    },
                ],
                end_date: [
                    {
                        required: true,
                        message: 'Bulan akhir tidak boleh kosong',
                    },
                ],
                distributor: [
                    {
                        required: true,
                        message: 'Distributor tidak boleh kosong',
                    },
                ],
            },
            shipping: {
                loading: false,
                storage1: [],
                storage2: [],
                labelx: [],
                labely: [{
                    type: 'value',
                    name: 'Jumlah DO/Pengiriman',
                    nameLocation: 'middle',
                    nameGap: 50,
                }],
                series: [],
                tooltip: {
                    formatter: function (params) {
                        let id = params.dataIndex

                        const tooltipValues = [];
                        tooltipValues.push(`<strong>${params.seriesName}</strong>`);
                        if (params.seriesIndex == 1) {
                            tooltipValues.push(`Bulan: ${state.shipping.storage1[id].bulan} <br />
                                Volume: ${state.shipping.storage1[id].volume} <br />
                                Total Transaksi: ${state.shipping.storage1[id].jumlah} <br />`);
                        } else {
                            tooltipValues.push(`Bulan: ${state.shipping.storage2[id].bulan} <br />
                                Volume: ${state.shipping.storage2[id].volume} <br />
                                Total Transaksi: ${state.shipping.storage2[id].jumlah} <br />`);
                        }
                        return tooltipValues.join('<br>');
                    },
                },
            },
            selling: {
                loading: false,
                labelx: [],
                labely: [
                    {
                        type: 'value',
                        name: 'Jumlah Volume (ton)',
                        nameLocation: 'middle',
                        nameGap: 50,
                    },
                    {
                        type: 'value',
                        name: 'Persentase Ach (Actual Volume/RKAP)',
                        axisLabel: {
                            formatter: '{value} %',
                        },
                        nameLocation: 'middle',
                        nameGap: 50,
                    },
                ],
                series: [],
            },
            cash_in_out: {
                loading: false,
                labelx: [],
                labely: [{
                    type: 'value',
                    name: 'Jumlah Nominal (Rupiah)',
                    nameLocation: 'middle',
                    nameGap: 100,
                }],
                series: [],
            },
        })

        const handleTableChangeCustomer = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataCustomerList()
        }

        const handleTableChangeDistributor = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataDistributorList()
        }

        const handleTableChangeBill = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataBillList()
        }

        const handleChangeRangedate = (val, mode) => {
            state.params.range_date = val;
        };

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list customer data
        const fetchDataCustomerList = () => {

            state.loading.customer = true

            apiClient
                .get('/api/dashboard/tabel-group-pelanggan', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data.customer = items
                    state.meta.customer.page = _meta.currentPage
                    state.meta.customer.per_page = _meta.perPage
                    state.meta.customer.total = _meta.totalCount
                })
                .finally(() => {
                    state.loading.customer = false
                })
        }

        // fetch list distributor data
        const fetchDataDistributorList = () => {
            state.loading.distributor = true

            apiClient
                .get('/api/dashboard/tabel-gpm-distributor', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data.distributor = items
                    state.meta.distributor.page = _meta.currentPage
                    state.meta.distributor.per_page = _meta.perPage
                    state.meta.distributor.total = _meta.totalCount
                })
                .finally(() => {
                    state.loading.distributor = false
                })
        }

        // fetch list bill data
        const fetchDataBillList = () => {
            state.loading.bill = true

            apiClient
                .get('/api/dashboard/summary-tagihan', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data.bill = items
                    state.meta.bill.page = _meta.currentPage
                    state.meta.bill.per_page = _meta.perPage
                    state.meta.bill.total = _meta.totalCount
                })
                .finally(() => {
                    state.loading.bill = false
                })
        }

        // fetch list shipping data
        const fetchChartShippingList = () => {
            state.shipping.loading = true

            apiClient
                .get('/api/dashboard/grafik-konfirmasi-pengiriman', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    state.shipping.storage1 = []
                    state.shipping.storage2 = []
                    if (data) {
                        state.shipping.labelx = [{
                            type: 'category',
                            data: data.map(item => item.bulan),
                        }]

                        data.forEach(item => {
                            state.shipping.storage1.push({
                                ...item,
                                ...item.dikonfirmasi,
                            })
                            state.shipping.storage2.push({
                                ...item,
                                ...item.menunggu,
                            })
                        })

                        state.shipping.series = [
                            {
                                name: 'Menunggu',
                                type: 'bar',
                                color: '#ff9800',
                                data: state.shipping.storage2.map(item => item.jumlah),
                                emphasis: {
                                    focus: 'series',
                                },
                                label: {
                                    show: true,
                                    position: 'top',
                                    formatter: function (params) {
                                        let id = params.dataIndex
                                        return `${state.shipping.storage2[id].jumlah}`
                                    },
                                },
                            },
                            {
                                name: 'Dikonfirmasi',
                                type: 'bar',
                                color: '#4caf50',
                                data: state.shipping.storage1.map(item => item.jumlah),
                                emphasis: {
                                    focus: 'series',
                                },
                                label: {
                                    show: true,
                                    position: 'top',
                                    formatter: function (params) {
                                        let id = params.dataIndex
                                        return `${state.shipping.storage1[id].jumlah}`
                                    },
                                },
                            },
                        ]
                    }

                })
                .finally(() => {
                    state.shipping.loading = false
                })
        }

        // fetch list selling data
        const fetchChartSellingList = () => {
            state.selling.loading = true

            apiClient
                .get('/api/dashboard/grafik-kinerja-penjualan', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    if (data.data) {
                        state.selling.labelx = [
                            {
                                type: 'category',
                                data: data.data.map(item => item.bulan),
                                axisPointer: {
                                    type: 'shadow',
                                },
                            },
                        ]
    
                        state.selling.series = [
                            {
                                name: 'Achieved',
                                type: 'line',
                                color: '#607d8b',
                                yAxisIndex: 1,
                                barGap: 0,
                                emphasis: {
                                    focus: 'series',
                                },
                                data: data.data.map(item => item.acknowledge),
                            },
                            {
                                name: 'RKAP',
                                type: 'bar',
                                color: '#ffc107',
                                barGap: 0,
                                emphasis: {
                                    focus: 'series',
                                },
                                data: data.data.map(item => item.rkap),
                            },
                            {
                                name: 'Actual Volume',
                                type: 'bar',
                                color: '#4caf50',
                                barGap: 0,
                                emphasis: {
                                    focus: 'series',
                                },
                                data: data.data.map(item => item.volume),
                            },
                        ]
                    }
                })
                .finally(() => {
                    state.selling.loading = false
                })
        }

        // fetch list cash in & out data
        const fetchChartCashInOurList = () => {
            state.cash_in_out.loading = true

            apiClient
                .get('/api/dashboard/grafik-cashin-cashout', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    if (data) {
                        state.cash_in_out.labelx = [{
                            type: 'category',
                            data: data.map(item => item.bulan),
                            axisPointer: {
                                type: 'shadow',
                            },
                        }]
    
                        state.cash_in_out.series = [
                            {
                                name: 'Cash Out',
                                type: 'line',
                                color: '#ffc107',
                                barGap: 0,
                                emphasis: {
                                    focus: 'series',
                                },
                                data: data.map(item => item.cash_out),
                            },
                            {
                                name: 'Cash In',
                                type: 'line',
                                color: '#4caf50',
                                barGap: 0,
                                emphasis: {
                                    focus: 'series',
                                },
                                data: data.map(item => item.cash_in),
                            },
                            {
                                name: 'Tagihan Distributor',
                                type: 'line',
                                color: '#3f51b5',
                                barGap: 0,
                                emphasis: {
                                    focus: 'series',
                                },
                                data: data.map(item => item.tagihan_distributor),
                            },
                        ]
                    }
                })
                .finally(() => {
                    state.cash_in_out.loading = false
                })
        }

        // download export excel customer
        const btnDownloadExcelCustomer = () => {
            state.loading.customerDownloading = true
            apiClient
                .get('/api/dashboard/tabel-group-pelanggan', {
                    params: {
                        ...queryParams(),
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `Summary-GPM-Perkelompok-Pelanggan_${moment().format('DDMMYY_HHmm')}.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loading.customerDownloading = false
                })
        }

        // download export excel distributor
        const btnDownloadExcelDistributor = () => {
            state.loading.distributorDownloading = true
            apiClient
                .get('/api/dashboard/tabel-gpm-distributor', {
                    params: {
                        ...queryParams(),
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `Summary-GPM-Perdistributor_${moment().format('DDMMYY_HHmm')}.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loading.distributorDownloading = false
                })
        }

        // download export excel bill
        const btnDownloadExcelBill = () => {
            state.loading.billDownloading = true
            apiClient
                .get('/api/dashboard/tabel-gpm-bill', {
                    params: {
                        ...queryParams(),
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `Summary-Tagihan_${moment().format('DDMMYY_HHmm')}.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loading.billDownloading = false
                })
        }

        const fetchDataList = async () => {
            await filterFormRef.value.validate()

            fetchDataCustomerList()
            fetchDataDistributorList()
            fetchDataBillList()
            fetchChartShippingList()
            fetchChartSellingList()
            fetchChartCashInOurList()
        }

        // handle vue
        onMounted(() => {
            // fetchDataList()

            // menghilangkan action kolom berdasarkan role
            if (hasRoles([ROLE_KAM])) {
                state.columns.customer = state.columns.customer.filter(item => {
                    if (!includes(['harga_tebus'], item.dataIndex)) {
                        return item
                    }
                })
            }

            // menghilangkan kolom berdasarkan role
            if (hasRoles([ROLE_KAM])) {
                state.columns.distributor = state.columns.distributor.filter(item => {
                    if (!includes(['total_harga'], item.dataIndex)) {
                        return item
                    }
                })
            }
        })

        return {
            fetchDataList,
            state,
            // rowSelection,
            filterFormRef,
            handleTableChangeCustomer,
            handleTableChangeDistributor,
            handleTableChangeBill,
            handleChangeRangedate,
            btnDownloadExcelCustomer,
            btnDownloadExcelDistributor,
            btnDownloadExcelBill,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
            // only role
            ROLE_KAM,
            hasRoles,
        }
    },
})
</script>
